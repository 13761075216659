<template>
    <div class="container-fluid">

        <div class="box-header">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="title-in-h">{{$t(info_page.lang_file+'.index.title')}}</h4>
                    <label v-show="info_page.status==='active'">{{$t(info_page.lang_file+'.index.has')+' '+ info_page.actives+' '+$t(info_page.lang_file+'.index.a_has_plural') }}</label>
                    <label v-show="info_page.status==='caducate'">{{$t(info_page.lang_file+'.index.has')+' '+ info_page.actives+' '+$t(info_page.lang_file+'.index.c_has_plural') }}</label>
                    <label v-show="info_page.status==='inactive'">{{$t(info_page.lang_file+'.index.has')+' '+ info_page.actives+' '+$t(info_page.lang_file+'.index.i_has_plural') }}</label>
                </div>

                <div class="col-md-8 pt-2">
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='active'?'btn-success':'btn-outline'" @click="change_status('active')">{{$t(info_page.lang_file+'.filter.vigencies')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='caducate'?'btn-success':'btn-outline'" @click="change_status('caducate')">{{$t(info_page.lang_file+'.filter.caducated')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='inactive'?'btn-success':'btn-outline'" @click="change_status('inactive')">{{$t(info_page.lang_file+'.filter.inactives')}}</button>
                </div>
            </div>
        </div>


    <div class="row">

        <vue-topprogress ref="topProgress"></vue-topprogress>
        <div class="col-12">
            <card v-loading="screenLoading" class="card-complete"
                  element-loading-lock="true"
                  element-loading-customClass="min-height-large"
                  :element-loading-text="screenText"
                  element-loading-spinner="el-icon-loading">
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="mb-3" style="width: 250px">

                            <l-button class="btn-default" @click="createItem()" v-if="writting_permission" >{{$t(info_page.lang_file+'.index.new')}}</l-button>
                        </div>
                        <el-input type="search"
                                  class="mb-3"
                                  style="width: 200px"
                                  :placeholder="$t(info_page.lang_file+'.index.search') "
                                  v-model="searchQuery"
                                  :isDraggable="true"
                                  aria-controls="datatables"/>
                    </div>
                    <div class="col-sm-12">

                        <el-table class="table-bigboy"
                                  style="width: 100%"
                                  :data="displayData">
                            <el-table-column min-width="80"  :label="$t(info_page.lang_file+'.index.name') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{row.name}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.service') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                           <!-- <label v-html="getVigency(row)"></label>-->
                                        <label>{{row.service?'Si':'No'}}</label>

                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.get_service') ">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label>{{row.get_service?'Si':'No'}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.form.qty') ">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label>{{row.qty}}</label>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.ingrese') ">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label v-if="row.reservations_mxn.length">{{formatMoney(row.reservations_mxn[0].revenue, 0)}}</label>
                                        <label v-else>{{formatMoney(0,0)}} </label>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.ingrese_usd') ">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label v-if="row.reservations_usd.length">{{formatMoney(row.reservations_usd[0].revenue, 0)}}</label>
                                        <label v-else>{{formatMoney(0,0)}} </label>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.order') ">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <el-select class="select-default"
                                                   v-model="row.order" @change="updateOrder(row._id, row.order)" style="width: 75px">
                                            <el-option v-for="item in info_page.max_order" class="select-default min-w-150" :value="item"
                                                       :label="item" >
                                            </el-option>
                                        </el-select>

                                    </div>

                                </template>
                            </el-table-column>

                            <el-table-column  width="180"  :label="$t('form.actions')" v-if="writting_permission">
                                <template slot-scope="{row}">
                                    <div class="text-right box-custom">


                                        <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row._id, row.status)"></l-switch>

                                        <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link"
                                           @click="handleEdit(row._id)"><i
                                                class="fa fa-edit"></i></a>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category" v-if="total>0">
                            {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                            {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                    </div>
                    <l-pagination class="pagination-no-border"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total="pagination.total">
                    </l-pagination>
                </div>
            </card>
        </div>
    </div>
        <div class="box-footer" :class="(records.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-danger btn-wd btn-default ml-1 mr-1"  @click="cancelForm">Cancelar</button>
                <button class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="saveChanges">Guardar</button>
            </div>
        </div>
    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import moment from 'moment';
    import CrudService from '../../../js/services/AddonService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    const crudService = new CrudService();
    const logFunctions = new LogFunctions();
    const logService = new LogService();
    import Settings from "src/js/helpers/Settings";
    let settings = new Settings();
    import { mapGetters } from "vuex";

    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser","selected_property","writting_permission"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    //result = this.fuseSearch.search(this.searchQuery)
                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            },

            displayData() {

                let result = JSON.parse(JSON.stringify(this.tableData))

                //onsole.log('cP', this.pagination.currentPage)
                /*if(this.pagination.currentPage>1){

                    this.pagination.activePage = this.pagination.currentPage;
                }*/
                if (this.searchQuery !== '') {


                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    //result = this.fuseSearch.search(this.searchQuery)
                    this.pagination.total = result.length
                }
                if (!this.tableData || this.tableData.length === 0) return [];

                if(this.pagination.activePage){
                    this.pagination.currentPage = this.pagination.activePage;
                    this.pagination.activePage = 0;
                }


                return result.slice(this.from, this.to)

            },
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                records:[],
                info_page:{
                    original_info:{},
                    section_name: 'addon',
                    status: 'active',
                    actives: 0,
                    inactives: 0,
                    contracts: [],
                    booking_url: '',
                    lang_file: 'addon',
                    response_name: 'addons',
                    max_order: 10
                },
                items: [],
                items_i: [],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,
                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                },
                section: 'Addon',
            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
             this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='Addons'){
                        this.chargerItems(property_id);
                    }
                })
            },


            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }
                this.screenLoading = true;
                this.screenText = text;
            },
            async chargerItems(property_id = null){

                this.info_page.property_id = property_id;
                this.info_page.booking_url = this.selected_property.booking_url;
                this.change_status('active');
                let params = {
                    property_id: property_id
                };
                let responseRequest =  await crudService.getTotalAddons(params);
                responseRequest = responseRequest.data.data[this.info_page.response_name];
                this.info_page.max_order = responseRequest;
                //console.log('responseRequest', responseRequest);
                this.configLogs(property_id, this.info_page.section_name);
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 2;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            formatMoney(value, decimals=2){
                value = Number(value)
                return '$ '+this.numberWithCommas(value);
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            async change_status(type){
                this.initLoading();
                let items = [];
                this.info_page.status = type;

                let property_id = this.selected_property._id;

                //console.log(this.info_page.actives, this.info_page.inactives)
                let params = {};


                if(type === 'active'){
                    params = {
                        property_id: property_id,
                        status: true,
                        expired: false
                    }
                }else if(type === 'caducate'){
                    params = {
                        property_id: property_id,
                        expired: true,
                        status: true,

                    }
                }else if(type === 'inactive'){
                    params = {
                        property_id: property_id,
                        status: false,
                    }
                }

                let responseRequest =  await crudService.getList(params);
                responseRequest = responseRequest.data.data[this.info_page.response_name];
                responseRequest.map(element => element.name =  element.name.find(item=> item.lang ==='es').text);
                this.info_page.actives = responseRequest.length;

                this.info_page.original_info= [...responseRequest]
                this.tableData = responseRequest;
                this.closeLoading();
            },
            consoleLog(title, info){
              console.log(title);
                console.log('---------------------------------');
                console.log(info);
                console.log('*********************************');
            },


            closeLoading(){
                this.screenLoading = false;
            },

            handleEdit(id) {
                this.$router.push({
                    name: 'EditAddon',
                    params: {
                        id: id
                    }
                })
            },
            handleDelete(row) {
                swal({
                    title: this.$t('form.question_delete'),
                    text: this.$t('form.warning_delete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: this.$t('form.yes_deleted'),
                    cancelButtonText: this.$t('form.cancel'),
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        /*roomService.deleteRoom(row.id).then(response => {
                            swal({
                                title: this.$t('form.deleted'),
                                text: this.$t('form.been_deleted'),
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                        });*/
                    }
                });



            },
            createItem(){
                this.$router.push({
                    name: 'CreateAddon'
                })
            },
            formatDate(date, typeD=null) {
                let value_r = null;
                switch (typeD) {
                    case 'short':
                        value_r = moment(date).utc().format('DD/MM');
                        break;

                    case 'normal':
                        value_r = moment(date).utc().format('DD/MM/YYYY');

                        break;
                    default:
                        value_r = date.utc().format('YYYY-MM-DD');
                        break;

                }
                return value_r
            },
            cancelForm(){
                this.chargerItems(this.selected_property._id);
                this.info_page.status = true;
                this.records = [];
            },


            updateStatus(id, status){
                let records = this.records;
                status = !status;

                let idx = records.findIndex(record => record._id === id);
                if(idx>=0){
                    records.splice(idx, 1);
                }else{
                    records.push({
                        _id: id,
                        status: status
                    })
                }
            },
            async updateOrder(id, order){
                let body = {
                    "new_order": order,
                    "addon_id": id
                }
                try {
                    await crudService.reorder(body)

                    let infoArray = this.info_page.original_info;
                    let itemFinded = infoArray.find(element=> element._id === id)

                    if(itemFinded){
                        await this.createLog(id, 1, [
                            {parameter: 'order',
                                old:itemFinded.order,
                                new:order}]);
                    }

                }catch (e) {
                    console.log('error', e);
                }
                this.change_status('active');
            },
            checkTimeZone(date){
                let gTimeZone = settings.getTimezone();

                let tzdetect =  moment(date)
                    .utc(gTimeZone)

                return tzdetect;

            },
            checkDate(datesSelected, action){

                //datesSelected = datesSelected.sort();
                let valueReturn = datesSelected[0];

                if(action==='max'){
                    valueReturn = datesSelected[datesSelected.length - 1];
                }
                return valueReturn;
            },
            alertCapture(booking_url, slug, date_start, date_end){


                date_start =  moment(date_start, 'YYYY-MM-DD');//.locale('es').format('DD-MMM-YYYY');
                date_end =  moment(date_end, 'YYYY-MM-DD');//.locale('es').format('DD-MMM-YYYY');
                let paramStart = date_start.format('YYYY-MM-DD');
                let paramEnd = date_start.add(3, 'days');



                if (date_end.isBefore(paramEnd, 'day')) {
                    paramEnd = date_end.format('YYYY-MM-DD');
                    //console.log('es un dia disponible', aux)
                }else{
                    paramEnd = paramEnd.format('YYYY-MM-DD')
                }






                let title_t = 'Obtener URL',
                    link = booking_url +'/landing/'+ slug+'?to='+paramEnd+'&from='+paramStart+'&adults=2&jrs=0&child=0&rateplan=',
                    text_succes = '',
                    text_error = '';

                //console.log('link', link)


                swal({
                    title: title_t,
                    html: '<div class="form-group">' +
                        '<input id="myTestField" type="text" class="form-control" value="'+link+'" readonly/>' +
                        '</div>',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    confirmButtonText: '<i class="fa fa-clone"></i>   Copiar al portapapeles',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    cancelButtonText: 'Cerrar',
                    buttonsStyling: false
                }).then( (result)=> {
                    if (result.dismiss !== 'cancel') {
                        let inputt = document.getElementById('myTestField');


                        inputt.select();

                        document.execCommand("copy");



                    }
                })
            },

            async saveChanges(){
                //console.log('items modificados', this.records)
                let records = this.records;

                let idx  = 0;
                while (idx < records.length){
                    let item = records[idx];
                    let id =item._id;
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }
                    let addon = await crudService.toggleStatus(id)
                    console.log('addon', addon)
                    addon = addon.data.data.Addon;
                    if(addon.status===false){

                        await this.createLog(addon._id, 2, []);
                    }else{
                        await this.createLog(addon._id, 1, [{
                            parameter: 'status',
                            old: false,
                            new: true
                        }]);
                    }



                    idx++;
                }

                this.records = [];

               /* setTimeout(() => {
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'true';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'false';
                    }

                    roomService.updateStatus(id, status).then(response => {

                        let room = response.data.data.room;
                        if(room.status===true){
                            this.items.push(room)
                        }else{
                            this.items_i.push(room)
                        }

                        this.info_page.actives =  this.items.length;
                        this.info_page.inactives =  this.items_i.length;
                    });
                }, 800);*/
            },

            trans_status(status){
                let result = 'Inactivo';
                if(status){
                    result = 'Activo';
                }
                return result
            },
            getVigency(itemInfo){
                let valueReturn = 'Sin vigencia';
                if(itemInfo.from && itemInfo.from!==null){
                    valueReturn =  moment(itemInfo.from, 'YYYY-MM-DD').locale('es').format('DD-MMM-YYYY');
                    valueReturn +=  ' <br> ';
                    valueReturn +=  moment(itemInfo.to, 'YYYY-MM-DD').locale('es').format('DD-MMM-YYYY');
                }
                return valueReturn;
            },

        },
        mounted() {
            this.changeProperty()
        }
    }
</script>
<style lang="scss">
    .el-table{
        table{
            .cell{
                word-break: break-word;
                .lbl-container{
                    label{
                        word-break: break-word;
                    }
                    .select-default{
                        width: 60px;
                    }
                }
            }
        }

    }
    .custom-lbl{
        label{
            color: #23CCEF;
            font-weight: 600;
        }
        span{
            width: 100%;
            display: inline-block;

        }
    }
    .content-table label{
        display: inline-block;
        width: 25%;
        font-size: 11px;
        text-transform: unset;
        order: 2;
        &:first-child{
            order: 3;
        }
    }
    .content-table label span{
        font-weight: 600;
    }
    .item-rule{
        overflow-x: hidden;
        .pl-c1{
            padding-left: .2rem;
        }
        .pr-c1{
            padding-right: .2rem;

        }
        &.header-r{
            span{
                line-height: 28px;
                height: 30px;

            }
        }
        span{
            white-space: nowrap;
            line-height: 16px;
            margin-bottom: 0;
            float: left;
            display: inline-block;
            height: 20px;
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            color: #9A9A9A;

        }
    }
    .card{
        min-height: 80vh;
        .label-row{
            white-space: nowrap;
            line-height: 16px;
            margin-bottom: 0;
            float: left;
        }
    }
    .bol-1{
        border-left: 1px solid #bebebe;
    }
    .bor-1{
        border-right: 1px solid #bebebe;
    }
    .bot-1{
        border-top: 1px solid #bebebe;
    }
    .bob-1{
        border-bottom: 1px solid #bebebe;
    }
    .box-custom{
        position: relative;
        .bootstrap-switch{
            margin-left: 15px;
            margin-right: 15px;
        }

        .icon-left{
            line-height: 23px;
            /*left: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            line-height: 30px;

*/
        }
    }
    .icon-right{
        line-height: 23px;
        margin-left: 15px;
        margin-right: 15px;
        width: 60px;
        display: inline-block;
        text-align: center;
    }
</style>
